/// <reference path = "./Location.d.ts" />

import Entity from 'core/framework/entity/Entity';

class LocationEntity extends Entity<Entities.LocationRaw> implements Entities.Location {
  getLat(): number {
    return Number(this.getEntity().lat || 0);
  }

  getLng(): number {
    return Number(this.getEntity().lng || 0);
  }
}

export default LocationEntity;
