import { isBrowser } from 'browser-or-node';
import { AuthService as OktaAuthService } from '@okta/okta-react';
import { navigate } from 'gatsby';

class AuthService implements AuthService.Constructor {
  static singleton: AuthService;
  private instance: AuthService.Instance;
  private config: AuthService.Config = {
    baseUrl: 'https://login.clickspray.com',
    issuer: 'https://login.clickspray.com/oauth2/default',
    redirectUri: isBrowser && window.location.origin + '/account/implicit/callback',
    clientId: '0oaablso0pEMDivJp4x6',
    scopes: ['openid', 'email', 'profile'],
    pkce: true,
    disableHttpsCheck: true,
  };

  public static getSingleton(): AuthService {
    if (!AuthService.singleton) {
      AuthService.singleton = new AuthService();
    }

    return AuthService.singleton;
  }

  constructor() {
    this.instance = isBrowser
      ? new OktaAuthService({
          ...this.config,
          onAuthRequired() {
            navigate('/account/login');
          },
        })
      : {};
  }

  public getInstance(): AuthService.Instance {
    return this.instance;
  }

  public getConfig(): AuthService.Config {
    return this.config;
  }
}

export default AuthService.getSingleton();
