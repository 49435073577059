/// <reference path = "./Entity.d.ts" />

import _ from 'lodash';

class Entity<E> implements Framework.Entity<E> {
  private entity: E;

  constructor(entity: E) {
    this.entity = _.cloneDeep(entity || {}) as E;
  }

  public getEntity(): E {
    return this.entity;
  }

  public toJSON(): E {
    return this.getEntity();
  }
}

export default Entity;
