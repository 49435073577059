import ClickAnalytics from './Analytic';
import { RewardsUserType } from 'services/enums';

class Analytics {
  public track(event: string): void {
    return ClickAnalytics.getInstance().track(event);
  }

  private page(page: string): void {
    return ClickAnalytics.getInstance().page(page);
  }

  public joinRewards() {
    this.page('sign-up-started');
    this.track('Join Rewards');
  }

  public login() {
    this.page('login-started');
    this.track('Login');
  }

  public signup() {
    this.page('sign-up-started');
    this.track('Sing Up');
  }

  public enterLastName() {
    this.page('enter-last-name');
    this.track('Sign Up: Enter Last Name');
  }

  public enterFirstName() {
    this.page('enter-first-name');
    this.track('Sign Up: Enter First Name');
  }

  public enterPassword() {
    this.page('enter-password');
    this.track('Sign Up: Enter Password');
  }

  public enterEmail() {
    this.page('enter-email');
    this.track('Sign Up: Enter Email');
  }

  public enterDateBirth() {
    this.page('enter-date-birth');
    this.track('Sign Up: Enter Date of Birth');
  }

  public enterPhone() {
    this.page('enter-phone');
    this.track('Sign Up: Enter Phone');
  }

  public enterRole(role: string) {
    this.page(`enter-role-${role.toLowerCase().replace(' ', '-')}`);
    this.track(`Sign Up: Enter Role ${role}`);
  }

  public enterGender() {
    this.page('enter-gender');
    this.track('Sign Up: Enter Gender');
  }

  public сheckEmails() {
    this.page('check-emails');
    this.track('Sign Up: Check Emails from Click');
  }

  public сheckTerms() {
    this.page('check-terms');
    this.track('Sign Up: Check Terms');
  }

  public privacyPolicy() {
    this.page('privacy-policy');
    this.track('Sign Up: Privacy Policy');
  }

  public firstStepComplete() {
    this.page('sign-up-first-step-complete');
    this.track('Sign Up: First Step Complete');
  }

  public enterAddress(role: RewardsUserType) {
    this.page('enter-address');
    this.track(`Sign Up ${this.userType(role)}: Enter Address`);
  }

  public enterZip(role: RewardsUserType) {
    this.page('enter-zip');
    this.track(`Sign Up ${this.userType(role)}: Enter Zip`);
  }

  public enterCity(role: RewardsUserType) {
    this.page('enter-city');
    this.track(`Sign Up ${this.userType(role)}: Enter City`);
  }

  public enterState(role: RewardsUserType) {
    this.page('enter-state');
    this.track(`Sign Up ${this.userType(role)}: Enter State`);
  }

  public enterRefferred(role: RewardsUserType) {
    this.page('enter-refferred');
    this.track(`Sign Up ${this.userType(role)}: Enter Refferred`);
  }

  public enterDispansary(role: RewardsUserType, name: string) {
    this.page(`dispansary-${name.toLowerCase().replace(' ', '-')}`);
    this.track(`Sign Up ${this.userType(role)}: Dispansary ${name}`);
  }

  public signupDone(role: RewardsUserType) {
    this.page('sign-up-done');
    this.track(`Sign Up ${this.userType(role)}: Continue`);
  }

  public logout() {
    this.page('logout');
    this.track('Log Out');
  }

  public account() {
    this.page('account');
    this.track('Account');
  }

  private userType(type: RewardsUserType) {
    switch (type) {
      case RewardsUserType.consumer:
        return 'Consumers';
      case RewardsUserType.shop_owner:
        return 'Shop Owner';
      case RewardsUserType.budtender:
        return 'Budtender';
    }
  }
}

export default new Analytics();
