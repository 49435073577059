import React, { useContext, useState, useEffect, createContext } from 'react';

import { getRewards } from 'services';
import useAuth from './useAuth';

export interface RewardProfile {
  loyaltyTierId: string;
  loyaltyTierName: string;
  availablePoints: number;
  redeemedPoints: number;
  awardedPoints: number;
  tiers: RewardTier[];
}

export interface RewardTier {
  id: string;
  name: string;
  minimumPoints: number;
}

const defaultState: RewardProfile = {
  loyaltyTierId: '',
  loyaltyTierName: '',
  availablePoints: 0,
  redeemedPoints: 0,
  awardedPoints: 0,
  tiers: [],
};

const RewardsContext = createContext(defaultState);

export const RewardsProvider: React.FC = ({ children }) => {
  const [rewards, setRewards] = useState(defaultState);
  const { token, isAuthenticated, profile } = useAuth().state;

  useEffect(() => {
    if (isAuthenticated) {
      getRewards(token as string)
        .then((res) => res.json())
        .then(setRewards);
    }
  }, [token, isAuthenticated, profile]);

  return <RewardsContext.Provider value={rewards}>{children}</RewardsContext.Provider>;
};

export default function useRewards() {
  return useContext(RewardsContext);
}
