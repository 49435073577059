export enum RewardsUserType {
  consumer = 'consumer',
  budtender = 'budtender',
  shop_owner = 'shop_owner',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum DispensariesFilterTypesEnum {
  sort = 'sort',
  filter = 'filter',
}

export enum DispensariesFilterEnum {
  proximity = 'proximity',
  all_stores = 'all_stores',
  southern_california = 'southern_california',
  northern_california = 'northern_california',
  // new_stores = 'new_stores',
}

export enum MembershipShopProducts {
  weekender_bag = 'weekender_bag',
  short_sleeve_shirt = 'short_sleeve_shirt',
  keychain = 'keychain',
  chill_towel = 'chill_towel',
}

export enum ClickTypes {
  go = 'go',
  restore = 'restore',
  chill = 'chill',
  dream = 'dream',
}

export enum CartActionTypes {
  setActiveType = 'setActiveType',
}

export enum AuthStausTypes {
  needAuthenticate = 'needAuthenticate',
  pending = 'pending',
  basicRegistrationCompleted = 'basicRegistrationCompleted',
  profileCompleted = 'profileCompleted',
  authenticated = 'authenticated',
}
