/// <reference path = "./PlaceDetails.d.ts" />

import Entity from 'core/framework/entity/Entity';
import LocationEntity from 'core/entities/location/Location';

class PlaceDetailsEntity extends Entity<Entities.PlaceDetailsRaw> implements Entities.PlaceDetails {
  getLocation(): Entities.Location {
    return new LocationEntity(this.getEntity().location);
  }

  getAddress(): string {
    return String(this.getEntity().address || '');
  }

  getPlaceName(): string {
    return String(this.getEntity().placeName || '');
  }

  getPlaceId(): string {
    return String(this.getEntity().placeId || '');
  }

  getTypes(): string[] {
    return (this.getEntity().types || []).map((type: string) => String(type || ''));
  }

  getUtcOffset(): number {
    return Number(this.getEntity().utcOffset || 0);
  }

  getNearbyStoresCount(): number {
    return Number(this.getEntity().nearbyStoresCount || 0);
  }

  getAddressCity(): string {
    return (
      (this.getEntity().components || []).find((component) => {
        return (component.types || []).includes('locality');
      })?.shortName || ''
    );
  }
}

export default PlaceDetailsEntity;
