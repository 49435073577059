import { AnalyticsService } from '@legalize/sdk';

import { marketId, legalizeApiUrl } from 'services/config';

class Analytics {
  static instance: Analytics;
  private analyticsService: AnalyticsService;

  constructor() {
    this.analyticsService = new AnalyticsService(legalizeApiUrl);
    this.analyticsService.init(marketId);
  }

  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }
    return Analytics.instance;
  }

  public track(event: string): void {
    this.analyticsService.track(event);
  }

  public page(page: string): void {
    this.analyticsService.page(page);
  }
}

export default Analytics;
