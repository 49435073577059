import { Profile } from './interfaces';
import PlaceDetailsEntity from 'core/entities/placeDetails/PlaceDetails';
import { baseUrl } from 'services/config';

export function signup(data: any): Promise<Response> {
  return fetch(`${baseUrl}/user/signup`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function saveInviteCode(data: any, accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/user/me/invite-code`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function inviteCustomer(data: any, accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/user/invite`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function uploadFile(file: any, accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/files`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
    method: 'POST',
    body: file,
  });
}

export function registerPurchase(data: any, accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/purchase`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function updateProduct(data: any, accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/purchase/update-product`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function getProfile(accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/user/me`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
  });
}

export function getRewards(accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/rewards/me`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
  });
}

export function getRewardsConfig(accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/user/me/rewards-config`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
  });
}

export function getRewardsTransactions(accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/rewards/me/transactions`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
  });
}

export function getDispensaries(query?: string, filter?: string): Promise<Response> {
  return fetch(
    `${baseUrl}/company/retailer?${query ? 'q=' + query : ''}${filter ? `&${filter}` : ''}`,
  );
}

export function getLegalizeToken(accessToken: string): Promise<Response> {
  return fetch(`${baseUrl}/user/me/legalize-token`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
  });
}

export function updateProfile(
  data: Profile,
  accessToken: string,
  ignoreReferralErrors?: boolean,
): Promise<Response> {
  return fetch(`${baseUrl}/user/me${ignoreReferralErrors ? '?ignoreReferralErrors=true' : ''}`, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function getPlaceDetailsByZipCode(zipCode: number): Promise<Entities.PlaceDetails> {
  const url = `${baseUrl}/location/zip-details?zip=${zipCode}`;
  const headers = new Headers({ 'Content-Type': 'application/json' });

  return fetch(url, { headers })
    .then((res) => res.json())
    .then((result) => Promise.resolve(new PlaceDetailsEntity(result)));
}

export function getPlaceDetailsByLocation(
  location: Entities.Location,
): Promise<Entities.PlaceDetails> {
  const url = `${baseUrl}/location/reverse-geocode?lat=${location.getLat()}&lng=${location.getLng()}`;
  const headers = new Headers({ 'Content-Type': 'application/json' });

  return fetch(url, { headers })
    .then((res) => res.json())
    .then((result) => Promise.resolve(new PlaceDetailsEntity(result)));
}

export async function getAutocomplete(query: string, sessionToken: string) {
  const url = `${baseUrl}/location/autocomplete?query=${query}&sessionToken=${sessionToken}`;
  const headers = new Headers({ 'Content-Type': 'application/json' });

  return fetch(url, { headers })
    .then((res) => res.json())
    .then((result) => Promise.resolve(result));
}

export async function getPlaceDetails(placeId: string, sessionToken: string) {
  const url = `${baseUrl}/location/place-details?placeId=${placeId}&sessionToken=${sessionToken}`;
  const headers = new Headers({ 'Content-Type': 'application/json' });

  return fetch(url, { headers })
    .then((res) => res.json())
    .then((result) => Promise.resolve(result));
}
