import OktaAuth from '@okta/okta-auth-js';
import React, { useContext, useEffect } from 'react';

import authService from 'core/services/authService/AuthService';
import * as api from 'services/clickApi';
import { RewardsUserType } from 'services/enums';
import { Profile } from 'services/interfaces';
import AuthAnalytics from 'core/framework/analytic/Auth';

import { RewardsProvider } from './useRewards';

export interface AuthState {
  isBasicRegistrationCompleted: boolean;
  isProfileCompleted: boolean;
  isAuthenticated: boolean;
  isPending: boolean;
  profile: Profile | undefined;
  authService: OktaAuthService | undefined;
  token: string | undefined;
}

const initialState: AuthState = {
  isBasicRegistrationCompleted: false,
  isProfileCompleted: false,
  isAuthenticated: false,
  isPending: true,
  profile: undefined,
  authService: undefined,
  token: undefined,
};

interface OktaAuthState {
  isAuthenticated: boolean;
  isPending: boolean;
  accessToken: string;
}

interface SignupInfo {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  dob: string;
  phone: string;
  gender: string;
  marketingSubscription: boolean;
  acceptedTermsOfService: boolean;
  rewardsUserType: RewardsUserType;
}

interface OktaAuthService {
  logout: () => void;
  setFromUri: (uri: string) => void;
  redirect: (opts: { sessionToken: string }) => void;
  login: (url: string) => void;
  _config: {
    baseUrl: string;
  };
}

type AuthAction =
  | {
      type: 'update_auth';
      payload: {
        profile?: Profile;
        authState: OktaAuthState;
        authService: OktaAuthService;
      };
    }
  | {
      type: 'update_profile';
      payload: {
        profile: Profile;
      };
    };

function isBasicRegistrationCompleted(profile?: Profile) {
  return !!(
    profile?.email &&
    profile?.firstName &&
    profile?.lastName &&
    profile?.dob &&
    profile?.gender &&
    profile?.rewardsUserType &&
    profile?.phone
  );
}

function isProfileCompleted(profile?: Profile) {
  switch (profile?.rewardsUserType) {
    case RewardsUserType.consumer:
      return true;
    case RewardsUserType.budtender:
      return !!profile?.company;
    case RewardsUserType.shop_owner:
      return !!profile?.company;
    default:
      return false;
  }
}

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'update_auth':
      return {
        ...state,
        isBasicRegistrationCompleted: isBasicRegistrationCompleted(action.payload?.profile),
        isProfileCompleted: isProfileCompleted(action.payload?.profile),
        profile: action.payload?.profile,
        isAuthenticated: action.payload.authState?.isAuthenticated,
        isPending: action.payload.authState?.isPending,
        authService: action.payload?.authService,
        token: action.payload.authState?.accessToken,
      };
    case 'update_profile':
      return {
        ...state,
        isBasicRegistrationCompleted: isBasicRegistrationCompleted(action.payload?.profile),
        isProfileCompleted: isProfileCompleted(action.payload?.profile),
        profile: action.payload?.profile,
      };
    default:
      throw new Error('Invalid action');
  }
};

const defaultDispatch: React.Dispatch<AuthAction> = () => initialState;

const AuthContext = React.createContext({
  state: initialState,
  dispatch: defaultDispatch,
});

const AuthProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [state, dispatch] = React.useReducer<React.Reducer<AuthState, AuthAction>>(
    reducer,
    initialState,
  );

  useEffect(() => {
    const setAuthState = async () => {
      const authState = await authService.getInstance().getAuthState();
      const loadProfile = async () => {
        let profile: Profile | undefined = undefined;
        if (!authState.isPending && authState.isAuthenticated) {
          profile = await api.getProfile(authState.accessToken).then((p) => p.json());
        }
        dispatch({
          type: 'update_auth',
          payload: {
            authService: authService.getInstance() as OktaAuthService,
            authState,
            profile: authState.isPending ? state.profile : profile,
          },
        });
      };
      await loadProfile();
    };

    const unsubscribeAuthChange = authService.getInstance().on('authStateChange', setAuthState);
    authService.getInstance().updateAuthState();
    return unsubscribeAuthChange;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <AuthProvider>
    <RewardsProvider>{element}</RewardsProvider>
  </AuthProvider>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAuth = () => {
  const { state, dispatch } = useContext(AuthContext);

  const saveProfile = async (
    profile: Profile,
    ignoreReferralErrors?: boolean,
  ): Promise<Profile | undefined> => {
    try {
      const res = await api.updateProfile(profile, state.token ?? '', ignoreReferralErrors);
      if (res.ok) {
        profile = await res.json();
      } else {
        const parsedResponse = await res.json();
        throw parsedResponse.message;
      }
      dispatch({
        type: 'update_profile',
        payload: {
          profile,
        },
      });
      return profile;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const fetchProfile = async () => {
    const res = await api.getProfile(state.token ?? '');
    if (res.ok) {
      const profile = await res.json();
      dispatch({
        type: 'update_profile',
        payload: {
          profile,
        },
      });
    } else {
      const parsedResponse = await res.json();
      throw parsedResponse.message;
    }
  };

  const logout = () => {
    state.authService?.logout();
  };

  const signup = async (complateRegistrationLink: string, data: Partial<SignupInfo>) => {
    const res = await api.signup(data);
    const oktaAuth = new OktaAuth({ issuer: authService.getConfig().issuer });
    if (res.ok) {
      const { sessionToken } = await oktaAuth.signIn({
        username: data.email,
        password: data.password,
      });
      AuthAnalytics.firstStepComplete();
      state.authService?.setFromUri(complateRegistrationLink);
      state.authService?.redirect({ sessionToken });
    } else {
      const parsedResponse = await res.json();
      throw parsedResponse.message;
    }
  };

  return { state, logout, saveProfile, fetchProfile, signup };
};

export default useAuth;
