export const isProduction: boolean = process.env.IS_PRODUCTION === 'true';

export const baseUrl: string = process.env.REST_API_URL || '';

export const legalizeApiUrl: string = process.env.LEGALIZE_API_URL || '';

export const marketId: number = Number(process.env.MARKET_ID);

export const brandIds: number[] = JSON.parse(process.env.BRAND_IDS || '');

export const categoryMerchIds: number[] = JSON.parse(process.env.CATEGORY_MERCH_IDS || '');

export const categoryClickIds: number[] = JSON.parse(process.env.CATEGORY_CLICK_IDS || '');

export const gatsbyWebsiteBaseUrl: string = process.env.GATSBY_WEBSITE_BASE_URL || '';
